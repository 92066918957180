import { useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/http/http.service";
import {
	MetriportConsolidatedDataResponse,
	MetriportConsolidatedQuery,
	MetriportConsolidatedStatusReponse,
	MetriportPatientResponse,
	MetriportQueryStatus,
} from "../models/entities/metriport.model";

const metriportApiKey = process.env.REACT_APP_METRIPORT_API_KEY!;
const httpClient = new HttpService(process.env.REACT_APP_METRIPORT_URL!);
const httpClient2 = new HttpService(process.env.REACT_APP_METRIPORT_API_URL!);

const getPatient = async (participantId: number) => {
	try {
		const patientResponse: MetriportPatientResponse = await httpClient.get(
			`/medical/v1/patient?filters=${participantId}&count=1`,
			{
				config: {
					headers: { "x-api-key": metriportApiKey, "Content-Type": "application/json" },
				},
			}
		);
		if (patientResponse && !patientResponse.patients.length) {
			throw 404;
		} else {
			return patientResponse.patients[0];
		}
	} catch (error: any) {
		if (error === 404) {
			throw new Error("PARTICIPANT.DASHBOARD.OVERVIEW.METRIPORT.STATE_ERROR_PATIENT");
		}
		throw new Error("UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR2");
	}
};

const startConsolidatedDataQuery = async (patientId: string) => {
	try {
		const consolidatedData: MetriportConsolidatedQuery = await httpClient.post(
			`/medical/v1/patient/${patientId}/consolidated/query`,
			{
				config: {
					headers: { "x-api-key": metriportApiKey, "Content-Type": "application/json" },
					params: {
						conversionType: "pdf",
					},
				},
				body: { metadata: {} },
			}
		);
		return consolidatedData;
	} catch (error) {
		throw new Error("UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR2");
	}
};

const getConsolidatedQueryStatus = async (patientId: string) => {
	try {
		const consolidatedStatus: MetriportConsolidatedStatusReponse = await httpClient.get(
			`/medical/v1/patient/${patientId}/consolidated/query`,
			{
				config: {
					headers: { "x-api-key": metriportApiKey, "Content-Type": "application/json" },
				},
			}
		);
		return consolidatedStatus;
	} catch (error) {
		throw new Error("UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR2");
	}
};

const waitConsolidatedQueryStatus = async (
	patientId: string,
	requestId: string
): Promise<MetriportConsolidatedQuery> => {
	return new Promise((resolve, reject) => {
		const interval = setInterval(async () => {
			// Get status
			const consolidatedStatusResponse = await getConsolidatedQueryStatus(patientId);
			// Verify if we need to refetch
			if (consolidatedStatusResponse.queries.length) {
				const tmpQuery = consolidatedStatusResponse.queries.find(
					(x) => x.requestId === requestId
				);
				if (tmpQuery?.status === MetriportQueryStatus.completed) {
					clearInterval(interval);
					resolve(tmpQuery);
				} else if (tmpQuery?.status === MetriportQueryStatus.failed) {
					clearInterval(interval);
					reject(tmpQuery);
				}
			}
		}, 1000); // Every second
	});
};

const getConsolidatedData = async (patientId: string) => {
	try {
		const consolidatedData: MetriportConsolidatedDataResponse = await httpClient2.get(
			`/report/${patientId}`
		);
		return consolidatedData;
	} catch (error) {
		throw new Error("UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR2");
	}
};

export const useMetriport = ({ participantId }: { participantId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["metriport-medical-report", { participantId }];

	const query = useQuery(queryKey, async () => {
		/**
		 * STEP 1: Get patient
		 * We need to get the patient in order to get it's Metriport internal ID.
		 * We need this internal ID to make the API calls.
		 */

		const patientResponse = await getPatient(participantId);

		/**
		 * STEP 2: Start a consolidated query
		 * We need to start a consolidated query, when the query will be processed, the results wil be send to the webhook
		 */

		const consolidatedQueryResponse = await startConsolidatedDataQuery(patientResponse.id);

		/**
		 * STEP 3: Wait for the query consolidation
		 * Once the consolidated data is ready, Metriport will send the payload to the webhook.
		 */

		const consolidatedStatusResponse = await waitConsolidatedQueryStatus(
			patientResponse.id,
			consolidatedQueryResponse.requestId
		);

		/**
		 * STEP 4: Get the report
		 * Get the metriport message payload
		 */

		const consolidatedDataResponse = await getConsolidatedData(patientResponse.id);

		return consolidatedDataResponse;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
