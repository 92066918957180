export enum MetriportQueryStatus {
	processing = "processing",
	failed = "failed",
	completed = "completed",
}

export interface MetriportPatientResponse {
	meta: {
		itemsOnPage: number;
		itemsInTotal: number;
	};
	patients: MetriportPatient[];
}

export interface MetriportPatient {
	address: {
		addressLine1: string;
		city: string;
		country: string;
		state: string;
		zip: string;
	};
	contact: {
		email: string;
		phone: string;
	}[];
	dateCreated: string; // Date
	dob: string; // Date (format: "2022-08-23")
	eTag: string;
	externalId: string; // Gladstone participantId (stringified)
	facilityIds: string[];
	firstName: string;
	genderAtBirth: "M" | "F";
	id: string;
	lastName: string;
}

export interface MetriportConsolidatedStatusReponse {
	message: string;
	queries: MetriportConsolidatedQuery[];
}

export interface MetriportConsolidatedQuery {
	requestId: string;
	status: MetriportQueryStatus;
	startedAt: string; // Date
	dateFrom: string; // Date (format: "2021-01-01")
	dateTo: string; // Date (format: "2021-01-31")
	resources: string[];
	conversionType: "pdf" | "html" | "json";
}

export interface MetriportConsolidatedDataResponse {
	created_on: string; // Date
	external_id: string; // Gladstone ID
	id: string;
	message_type: "medical.consolidated-data";
	status: MetriportQueryStatus;
	metadata: string; // Stringified JSON: MetriportConsolidatedData
}

export interface MetriportConsolidatedData {
	meta: {
		messageId: string;
		when: string; // Date
		type: "medical.consolidated-data";
		data: any;
	};
	patients: [
		{
			bundle: {
				type: "searchset";
				entry: [
					{
						resource: {
							content: [
								{
									attachment: {
										url: string;
										contentType: string; // "application/pdf"
									};
								},
							];
							subject: {
								reference: string;
							};
							resourceType: "DocumentReference";
						};
					},
				];
				total: number;
				resourceType: string;
			};
			status: MetriportQueryStatus;
			filters: {
				resources: string;
				generateAiBrief: boolean;
			};
			patientId: string; // Metriport internal ID
			externalId: string; // Gladstone ID (stringified)
		},
	];
}
